import { LitElement, html, css } from 'lit'
import { until } from 'lit/directives/until.js'
import { isUndefined } from 'is-what'
// import '@voicify/voicify-browser-assistant-sdk'

// https://www.npmjs.com/package/haunted

const voicify = ({
    disabled,
    appId = 'your-voicify-app-id',
    appKey = 'your-voicify-app-key',
    header = {},
    body = {},
    toolbar = {},
    start = {},
}) => {
    if (disabled) return
    const initializeVoicifyAssistant = () =>
        window?.initializeVoicifyAssistant({
            serverRootUrl: 'https://assistant.voicify.com',
            appId,
            appKey,
            textToSpeechProvider: 'Google', // Options: Google, Polly
            locale: 'en-US',
            textToSpeechVoice: 'Female', // Polly Voices: https://docs.aws.amazon.com/polly/latest/dg/voicelist.html, Google Voices: Unspecified, Male, Female and Neutral
            channel: 'My App',
            device: 'My device',
            autoRunConversation: false, // This will continuously keep the microphone open after initial click, but will time out if speech isn't recognized.
            initializeWithWelcomeMessage: true,
            useOutputSpeech: true,
            useDraftContent: true,
            useVoiceInput: true,
            sessionTimeout: 60 * 60 * 1000, // This should be a number (in milliseconds).
            // environmentId: "your-custom-voicify-environment-id", // This value is optional.
            noTracking: true, // This value is optional and defaults to true. Set to false to turn off analytics tracking.
            // useDraftContent: true, // This value is optional and defaults to false. Set to true to use conversation items in "draft" state.
            uiType: 'bottomRightButton', // Options: "bottomRightButton", "slideFromRight", "slideFromBottom"
            customStyles:
                '.voicify-assistant-custom-style { color: blue; font-style: italic; }', // This value is optional. Add custom CSS in string format here.
            styles: {
                assistant: {},
                header,
                body,
                toolbar,
                start,
                // header: {
                //   // assistantName: "blah",
                //   // assistantImage: "https://www.svgrepo.com/show/424622/alarm-alert-bell.svg",
                //   // assistantNameTextColor: "yellow",
                //   // backgroundColor: "linear-gradient(#212d38, #597993)",
                //   // fontFamily: "Helvetica",
                //   // fontSize: "40px",
                // },
                // body: {
                //   // assistantImage: 'https://www.svgrepo.com/show/424622/alarm-alert-bell.svg',
                //   // backgroundColor: "blue",
                //   // borderBottomColor: "yellow",
                //   // borderTopColor: "yellow",
                //   // hintsBackgroundColor: "whitesmoke",
                //   // hintsBorderColor: "red",
                //   // hintsBorderStyle: "solid",
                //   // hintsFontFamily: "Helvetica",
                //   // hintsFontSize: "20px",
                //   // messageReceivedBackgroundColor: "whitesmoke",
                //   // messageReceivedBorderColor: "red",
                //   // messageReceivedBorderStyle: "solid",
                //   // messageReceivedFontFamily: "Helvetica",
                //   // messageReceivedFontSize: "20px",
                //   // messageReceivedTextColor: "navy",
                //   // messageSentBackgroundColor: "pink",
                //   // messageSentBorderColor: "orange",
                //   // messageSentBorderStyle: "solid",
                //   // messageSentFontFamily: "Helvetica",
                //   // messageSentFontSize: "30px",
                //   // messageSentTextColor: "navy",
                // },
                // toolbar: {
                //   // backgroundColor: "purple",
                //   // textboxActiveHighlightColor: "purple",
                //   // textboxInactiveHighlightColor: "purple",
                //   // assistantStateFontColor: "pink",
                //   // assistantStateTextColor: "pink",
                //   // equalizerColor: "pink",
                //   // fullSpeechResultTextColor: "pink",
                //   // helpTextFontColor: "pink",
                //   // muteFontColor: "pink",
                //   // speakActiveTitleColor: "pink",
                //   // textInputCursorColor: "pink",
                //   // textInputTextColor: "pink",
                //   // typeActiveTitleColor: "pink",
                //   // assistantStateFontFamily: "Helvetica",
                //   // helpTextFontFamily: "Helvetica",
                //   // muteFontFamily: "Helvetica",
                //   // speakFontFamily: "Helvetica",
                //   // textboxfontFamily: "Helvetica",
                //   // typeFontFamily: "Helvetica",
                //   // placeholder: "meow",
                // },
                // start: {
                //   // assistantImage: 'https://www.svgrepo.com/show/424622/alarm-alert-bell.svg',
                // },
            },
        })

    if (isUndefined(window?.initializeVoicifyAssistant)) {
        setTimeout(() => {
            console.log(
                '%c Service Worker Running!',
                'font-weight: bold; font-size: 50px;color: red; text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113); margin-bottom: 12px; padding: 5%'
            )
            initializeVoicifyAssistant()
        }, 1000)
    } else {
        initializeVoicifyAssistant()
    }
}

const fetchOnMount = async clientId => {
    const response = await fetch(
        `https://s3.amazonaws.com/p.assets.lssdev.com/widget/${clientId}.json`
    ).catch(err => err)

    if (response.ok) {
        const json = await response.json()
        // Add some delay for demo purposes
        // await new Promise(r => setTimeout(() => r(), 3000))
        voicify(json)
        return json
    } else {
        throw `Error ${response.status}`
    }
}

class RumbleWidget extends LitElement {
    static properties = {
        // Props
        clientId: { type: String },
        // END
        bodyText: {},
        label: {},
        editing: {},
        value: {},
        content: { state: true },
        responseData: { type: String },
    }

    // static styles = css`
    //     .card-widget {
    //         display: flex;
    //         flex-direction: column;
    //         align-items: center;
    //         width: 260px;
    //         background-color: white;
    //         padding: 20px;
    //         border-radius: 5%;
    //         /* Add shadows to create the "card" effect */
    //         box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    //         transition: 0.3s;
    //     }
    //     /* On mouse-over, add a deeper shadow */
    //     .card-widget:hover {
    //         box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    //     }
    //     .button-widget {
    //         background-color: #4caf50; /* Green */
    //         border: solid 1px #4caf50;
    //         color: white;
    //         padding: 15px 32px;
    //         text-align: center;
    //         text-decoration: none;
    //         display: inline-block;
    //         font-size: 16px;
    //         border-radius: 4px;
    //     }
    //     .button-widget {
    //         transition-duration: 0.4s;
    //     }

    //     .button-widget:hover {
    //         background-color: white; /* Green */
    //         color: #4caf50;
    //         border: solid 1px #4caf50;
    //     }
    // `

    constructor() {
        super()
        this.clientId
        this.bodyText = 'Rumble!'
        this.label = 'A label, for ARIA.'
        this.editing = false
        this.value = 7
        // this.attachShadow({ mode: 'open' }) // shadowRoot can override
        // this.content = fetch('https://jsonplaceholder.typicode.com/todos/1')
        //     .then(r => r.json())
        //     .then(r => r.title)
        // this.content = fetchOnMount(1)
        this.content
    }

    connectedCallback() {
        super.connectedCallback()
        const script = document.createElement('script')
        script.src =
            'https://sdk.voicify.com/browser-sdk/1.1.4/voicifyAssistant.js'
        document.head.appendChild(script)

        console.log('connected', this.clientId)
        this.content = fetchOnMount(this.clientId)
    }

    // shouldUpdate(changedProperties) {
    //     changedProperties.forEach((oldValue, propName) => {
    //       console.log(`${propName} changed. newValue ${this[propName]}. oldValue: ${oldValue}`);
    //       if((propName === 'width' || propName === 'height') && !isUndefined(oldValue)) {
    //         console.log(this[propName])
    //         // voicify(this[propName])
    //       }
    //     });
    //     changedProperties.has(['width', 'heigth'])
    //     return
    //   }

    /* Needed for Rumble. Not for Blaster */
    // render() {
    //     return html`
    //         <!-- event listener expression -->
    //         <div class="card-widget">
    //             <div>
    //                 <button class="button-widget" @click="${this.clickHandler}">
    //                     ${this.editing ? 'Hide Widget' : 'Show Widget'}
    //                 </button>
    //             </div>
    //             <br />
    //             <div>
    //                 ${this.editing
    //                     ? html`<!-- Child  expression -->
    //                           <h2>Widget: ${this.bodyText}</h2>
    //                           <p>Client ID: ${this.clientId}</p>
    //                           <h4>
    //                               Request Data:
    //                               ${until(
    //                                   this.content,
    //                                   html`<span>Loading...</span>`
    //                               )}
    //                           </h4>

    //                           <!-- attribute expression -->
    //                           <div aria-label=${this.label}>
    //                               Attribute expression.
    //                           </div>
    //                           <br />

    //                           <!-- Boolean attribute expression -->
    //                           <div>
    //                               Boolean attribute expression.
    //                               <input
    //                                   type="text"
    //                                   ?disabled=${!this.editing}
    //                               />
    //                           </div>

    //                           <!-- property expression -->
    //                           <div>
    //                               Property expression.
    //                               <input
    //                                   type="number"
    //                                   .valueAsNumber=${this.value}
    //                               />
    //                           </div>
    //                           <br />
    //                           <div>
    //                               <button
    //                                   class="button-widget"
    //                                   @click="${() => this.getData(2)}"
    //                               >
    //                                   Get Some Data
    //                               </button>
    //                           </div>
    //                           <h3>${this.responseData}</h3>
    //                           <br />
    //                           <img
    //                               src="https://tfwiki.net/mediawiki/images2/thumb/7/7b/Rumblevsperceptor.jpg/220px-Rumblevsperceptor.jpg"
    //                               width="200"
    //                           />
    //                           <br />`
    //                     : html`<div>*** Hidden Widget Controls ***</div>`}
    //             </div>
    //         </div>
    //     `
    // }

    clickHandler(e) {
        this.editing = !this.editing
        // console.log(e.target)
    }

    async getData(id) {
        const response = await fetch(
            `https://jsonplaceholder.typicode.com/todos/${id}`
        )
        const json = await response.json()
        this.responseData = json.title
        this.requestUpdate()
    }
}
customElements.define('rumble-widget', RumbleWidget)
